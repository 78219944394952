#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}
html,
body {
  height: 100%;
  letter-spacing: 0.5px;
}
nav {
  .nav-link {
    font-family: "AeonikRegular";
    color: $black !important;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    &:last-child {
      padding-right: 0 !important;
    }
  }
  .logo {
    width: 146px;
    height: 50px;
  }
  .dropdown-menu {
    padding: 0 20px 0 20px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-left: 20px;

    .dropdown-item {
      border-top: 1px solid #ccc;
      padding-left: 0;
      padding-right: 0;
      position: relative;

      &:active {
        background-color: #fff;
        color: #212529;
      }
      &:focus {
        background-color: #fff;
        color: #212529;
      }
      &:hover {
        background-color: rgb(233, 233, 233);
        color: #212529;
      }
      @media (max-width: 1199px) {
        border-top: none;
        &::before {
          content: "";
          height: 2px;
          width: 12px;
          background-color: #212529;
          position: absolute;
          left: -19px;
          top: 50%;
        }
      }
    }
  }
  .dropdown {
    .dropdown-toggle {
      &::after {
        position: relative;
        top: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 17L6 9L18 9L12 17Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        border: none;
        width: 18px;
        height: 15px;
        background-position: center center;
        background-size: contain;
      }
    }
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}
.navbar {
  padding-top: 6.375px;
  padding-bottom: 6.375px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
  .navbar-nav {
    @media (max-width: 1199px) {
      height: 100vh;
    }
  }
}
.scrolled-down {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}
.scrolled-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}
.heroBanner {
  margin-top: 65px;
  height: calc(100vh - 63px);
  min-height: 694px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-attachment: fixed;

  .heroBannerContainer {
    height: 100%;

    h1 {
      margin-top: 72px;
      color: #fff;
      font-family: "AeonikRegular";
      font-size: 72px;
      line-height: 72px;
      margin-bottom: 35px;
    }
    .subtitle {
      color: #fff;
      font-family: "AeonikRegular";
      font-size: 24px;
      line-height: 30px;
    }
  }
}
.heroBannerVideo {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  background-image: none !important;
  background-color: #fff;

  video,
  .videoDiv {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    max-height: 694px;
  }
  .darkOverlay {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 2;
  }
  .heroBannerContainer {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
}
.heroBannerMobile {
  display: none;
}
.heroBannerVideoMobile {
  display: none;
}
.heroBannerShort {
  //height: 512px;
  //min-height: 512px;

  .heroBannerContainer {
    .row {
      h1 {
        margin-top: 72px;
      }
    }
  }
}
.Fiftyfiftyrepeater {
  padding-top: 20px;
  padding-bottom: 20px;

  .container {
    .row {
      margin-top: 57px;
      margin-bottom: 57px;
      .videoDiv {
        video {
          height: 100%;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        min-height: 348px;
      }
      h2 {
        color: $black;
        font-family: "AeonikRegular";
        font-size: 24px;
        line-height: 30px;
        width: 100%;
        max-width: 404px;
        margin: 0 auto 10px auto;
      }
      p {
        color: $black;
        font-family: "AeonikRegular";
        width: 100%;
        max-width: 404px;
        margin: 0 auto;
      }
    }
  }
}
.Fiftyfiftyhighlights {
  margin: 40px 0;

  .container {
    .row {
      .left-item {
        border-right: 1px solid $grey;
      }
      .item {
        padding: 30px 0px;

        .ico {
          width: 100%;
          max-width: 48px;
          height: 100%;
          max-height: 48px;
          margin-bottom: 20px;
        }
        h3 {
          font-family: "AeonikRegular";
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 10px;
          color: $black;
        }
        p {
          max-width: 404px;
          margin: 0 auto 20px auto;
          font-family: "AeonikRegular";
          color: $black;
        }
      }
    }
  }
}
.Fullwidthimage {
  height: 512px;
  min-height: 512px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .container {
    height: 100%;

    .row {
      height: 100%;

      .col-md-6 {
        align-items: center;
        display: flex;

        .textitem {
          color: #fff;
          font-size: 132px;
          margin-top: -40px;
          width: 100%;
        }
        .text-right {
          text-align: right;
        }
      }
    }
  }
}
.FullwidthimageMobile {
  display: none;
}
.Onethirdrepeater {
  margin-top: 70px;
  margin-bottom: 50px;

  .container {
    .row {
      .col-md-4 {
        font-family: "AeonikRegular";
        text-align: center;
        padding: 0 26px;
        img {
          width: 48px;
          height: 48px;
          margin-bottom: 20px;
        }
        h2 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.Globalbrands {
  .container {
    border-top: 1px solid $grey;

    .col {
      text-align: center;
      font-family: "AeonikRegular";

      h2 {
        margin: 70px 0px;
        font-size: 36px;
        line-height: 43px;
      }
      img {
        max-width: 130px;
        width: 100%;
        margin: 0 40px 0;
      }
    }
  }
}
footer {
  margin-top: auto;
  padding-top: 30px;
  padding-bottom: 100px;
  background: $black;
  color: #fff;
  .footer-heading {
    margin-bottom: 4px;
  }
  .footer-heading-small {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .pages {
    display: flex;
    flex-flow: column;
    .footer-heading {
      margin-bottom: 0px;
    }
    .footer-heading-small {
      margin-top: 10px;
      margin-bottom: 4px;
      font-size: 12px;
    }
    .icopages {
      display: flex;
      align-items: center;
    }
    a {
      color: #fff;
      font-family: "AeonikRegular";
      font-size: 18px;
      text-decoration: none;

      &:hover {
        color: $grey;
        transition: 0.2s ease-in-out;
        text-decoration: none;
      }
    }
  }
}
.ContentAbout {
  padding-top: 36px;
  padding-bottom: 36px;

  .container {
    h3 {
      font-size: 24px;
      line-height: 36px;
    }
    p {
      font-size: 18px;
    }
    ul {
      margin-left: 20px;

      li {
        list-style-type: disc;
      }
    }
    img {
      min-height: 348px;
      object-fit: cover;
      width: 100%;
      max-width: 100%;
      margin-bottom: 36px;
    }
  }
}
.PricingTable {
  .container {
    .row {
      .table {
        color: #666666;
        font-size: 18px;
        font-family: "AeonikRegular";
        margin: 48px 0px;

        thead {
          color: $black;

          tr {
            border-top: none;
            border-bottom: $black;

            th {
              border-left: none;
              border-right: none;
            }
          }
        }
        tbody {
          tr {
            td {
              border-left: none;
              border-right: none;
            }
          }
          &:last-child {
            border-bottom: $black;
            color: $black;
          }
        }
        &:first-child {
          border-top: $black;
        }
      }
      .tableDescription {
        margin-bottom: 72px;
      }
    }
  }
}
.Fullwidthbuttons {
  .container {
    margin-top: 70px;
    margin-bottom: 120px;

    .leftCol {
      .btn-primary {
        margin-left: auto;
      }
    }
  }
}
.Testimonials {
  .container {
    margin-top: 100px;
    margin-bottom: 70px;
    max-width: 1000px;

    .carousel {
      .carousel-inner {
        max-width: 620px;
        margin: 0 auto;

        .carousel-item {
          min-height: 270px;

          .copy-container {
            display: flex;
            flex-flow: column;
            min-height: 270px;

            .copy {
              color: $black;
              font-size: 24px;
              line-height: 30px;
              margin-bottom: 18px;
              text-align: center;
              p {
                margin-bottom: 0;
              }
            }
            .author {
              margin-top: auto;
            }
            .author,
            .position {
              font-size: 18px;
              color: $black;
              line-height: 24px;
              margin-bottom: 5px;
              text-align: center;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .carousel-control-prev {
        .carousel-control-prev-icon {
          width: 50px;
          height: 50px;
          background-image: url(../img/3Dctrl_Arrow_nav_left.svg);
        }
      }
      .carousel-control-next {
        .carousel-control-next-icon {
          width: 50px;
          height: 50px;
          background-image: url(../img/3Dctrl_Arrow_nav_right.svg);
        }
      }
    }
  }
}
.Contact {
  margin-top: 36px;
  margin-bottom: 72px;

  .mfields {
    margin-top: 40px;
  }
  .mfieldsContainer-mobile {
    display: none;
  }
  .btn {
    margin-top: 50px;
  }
}
.blogPage {
  margin-top: 108px;
  margin-bottom: 116px;
  .filterRow {
    align-items: center;
    margin-bottom: 15px;

    .filterTitle {
      width: fit-content;
      font-size: 14px;
      line-height: 14px;
      color: $black;
    }
    .filterButton {
      display: block;
      width: fit-content;
      background: transparent;
      border: none;
      color: #ccc;
      font-size: 14px;
      line-height: 14px;
      padding: 0 6px;
    }
    .filterButton.active {
      color: $black;
    }
  }
  .postCard {
    margin-bottom: 67px;
    a {
      color: $black;
      text-decoration: none;
      display: flex;
      flex-flow: column;
      .videoDiv {
        height: 227px;
        video {
          height: 227px;
          object-fit: cover;
        }
      }
      img {
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 227px;
      }
      .postTag {
        font-size: 14px;
        margin-top: 5px;
      }
      .postTitle {
        font-size: 24px;
        line-height: 30px;
      }
      .postExcerpt {
        font-size: 18px;
      }
    }
  }
}
.blogItem {
  margin-top: 74px;

  .container {
    margin-top: 34px;
    margin-bottom: 72px;
    .blogShareMobile {
      display: none;
    }
    .blogPostBody {
      .blogShare {
        margin-top: 215px;
        .blogShareItemTitle {
          color: #999;
        }
      }

      .blogPostContent {
        border-left: 1px solid #cccccc;

        * {
          max-width: 620px;
          width: 100%;
        }

        img:first-of-type {
          width: 100% !important;
          max-width: 100% !important;
        }
        div:first-of-type {
          width: 100% !important;
          max-width: 728px !important;
          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}
.TutorialsPage {
  margin-top: 108px;
  margin-bottom: 116px;

  .container {
    .row {
      .postCard {
        margin-bottom: 67px;

        .postDate {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .postTitle {
          font-size: 24px;
          margin-bottom: 10px;
        }
        .excerpt {
          font-size: 18px;
        }
        img {
          max-width: 100%;
          width: 100%;
          object-fit: cover;
          height: 100%;
          max-height: 227px;
        }
      }
    }
  }
}
.modal {
  .modal-dialog {
    margin-top: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      height: 100%;
      max-width: 836px;
      margin: 0 auto;
      max-height: 70vh;

      .modal-header {
        border: 0;
      }
    }
  }
}
.FaqPage {
  margin-top: 32px;
  margin-bottom: 72px;

  .container {
    .row {
      .accordion {
        .accordion-button {
          color: $black;
          font-size: 24px;
          &::after {
            content: "+";
            background-image: none;
            position: relative;
            transform: none;
          }
        }
        .accordion-body {
          color: $black;
          font-size: 18px;
        }
        .accordion-button:not(.collapsed) {
          background-color: transparent;
          &::after {
            content: "-";
            background-image: none;
            position: relative;
            transform: none;
          }
        }
        .accordion-button:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
.RelatedBlogItems {
  margin-bottom: 72px;
  margin-top: 0;

  .container {
    h3 {
      font-size: 24px;
      padding-top: 36px;
      margin-bottom: 45px;
      border-top: 1px solid #cccccc;
    }

    .postCard {
      margin-bottom: 0;
    }
  }
}

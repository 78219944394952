@media (max-width: 1199px) {
  .heroBanner {
    .heroBannerContainer {
      h1 {
        font-size: 62px;
        line-height: 62px;
      }
      .subtitle {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  .blogPage {
    .postCard {
      a {
        display: block;
        img {
          min-height: 227px;
        }
      }
    }
  }
  .TutorialsPage {
    .postCard {
      img {
        min-height: 227px;
      }
    }
  }
  .Fullwidthimage {
    .container {
      .row {
        .col-md-6 {
          .textitem {
            font-size: 100px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .heroBanner {
    min-height: 580px;
    .heroBannerContainer {
      h1 {
        font-size: 52px;
        line-height: 52px;
      }
    }
  }
}
@media (max-width: 991px) {
  .heroBanner {
    .heroBannerContainer {
      h1 {
        font-size: 48px;
        line-height: 48px;
      }
      .subtitle {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  .blogPage {
    .postCard {
      margin-bottom: 47px;

      a {
        display: block;
        img {
          min-height: 160px;
        }
        .postTitle {
          font-size: 20px;
          line-height: 24px;
          min-height: initial;
          margin-bottom: 15px;
        }
        .postExcerpt {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
  .TutorialsPage {
    .container {
      .row {
        .postCard {
          margin-bottom: 47px;
          img {
            min-height: 160px;
            height: 160px;
          }
          .postTitle {
            font-size: 20px;
            line-height: 24px;
            min-height: initial;
            margin-bottom: 15px;
          }
          .excerpt {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 80%;
        max-height: 60vh;
      }
    }
  }
  .Fullwidthimage {
    .container {
      .row {
        .col-md-6 {
          .textitem {
            font-size: 90px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .navbar {
    .nav-link {
      font-size: 30px;
      border-bottom: 1px solid #cccccc;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .nav-dropdown {
      border: none;
    }
    .dropdown-menu {
      margin-left: 0px;
      .dropdown-item {
        border-top: none !important;
        //border-bottom: 1px solid #ccc !important;
        font-size: 30px;
      }
    }
  }
  .heroBanner {
    height: 190px;
    min-height: 190px;
    max-height: 190px;
    background-attachment: scroll;

    .container {
      display: none;
    }
  }
  .heroBannerVideo {
    display: none;
  }
  .heroBannerVideoMobile {
    display: block;
  }
  .heroBannerMobile {
    display: grid;
    background-image: none !important;
    margin-bottom: 36px;

    h1 {
      font-size: 30px;
      margin: 24px 0 18px 0;
    }
    .subtitle {
      margin-bottom: 18px;
    }
    .btn-primary {
      margin: 0 auto;
    }
  }
  .Fiftyfiftyrepeater {
    padding-top: 0;
    padding-bottom: 0;
    .container {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      .row {
        margin-top: 36px;
        margin-bottom: 36px;
        &:nth-child(even) {
          flex-flow: column-reverse;
        }

        img {
          max-height: 183px;
          min-height: 183px;
          margin-bottom: 24px;
        }
        .arrowsLeft {
          transform: rotate(90deg);
        }
        .arrowsRight {
          transform: rotate(270deg);
        }
      }
    }
  }
  .Fiftyfiftyhighlights {
    margin: 18px 0px 0px 0px;
    .container {
      .row {
        .item {
          padding: 18px 0px;
        }
        .left-item {
          border: none;
        }
      }
    }
  }
  .Fullwidthbuttons {
    .container {
      margin-top: 36px;
      margin-bottom: 72px;
      .row {
        .leftCol {
          .btn-primary {
            margin: 0 auto 24px auto;
          }
        }
        .rightCol {
          .btn-secondary {
            margin: 0 auto;
          }
        }
      }
    }
  }
  .Fullwidthimage {
    height: 140px;
    min-height: 140px;
    background-attachment: scroll;
    .container {
      display: none;
    }
  }
  .FullwidthimageMobile {
    display: block;
    .textitem {
      text-align: center;
      font-size: 30px;
      margin: 36px 0;
    }
  }
  .Onethirdrepeater {
    margin-top: 0;
    margin-bottom: 0;
  }
  .Globalbrands {
    h2 {
      margin-top: 36px !important;
      margin-bottom: 36px !important;
      font-size: 30px;
    }
    img {
      margin-bottom: 60px !important;
    }
  }
  .Testimonials {
    .container {
      margin-top: 0;
      .carousel {
        .carousel-inner {
          max-width: 80%;
        }
        .carousel-control-prev {
          .carousel-control-prev-icon {
            width: 15px;
            height: 25px;
            margin-right: auto;
          }
        }
        .carousel-control-next {
          .carousel-control-next-icon {
            width: 15px;
            height: 25px;
            margin-left: auto;
          }
        }
      }
    }
  }
  footer {
    .logo {
      margin-bottom: 25px;
    }
    .footer-heading-small {
      margin-top: -20px;
      margin-bottom: 20px;
      font-size: 12px;
    }
    .pages {
      .footer-heading-small {
        margin-top: -20px;
        margin-bottom: 20px;
        font-size: 12px;
      }
      &:nth-of-type(4) {
        display: none;
      }
      &:nth-of-type(3) {
        margin-top: 25px;
        a {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: none;
          }
          &:nth-of-type(3) {
            display: none;
          }
          &:nth-of-type(4) {
            display: none;
          }
        }
      }
    }
  }
  .hideMobile {
    display: none;
  }
  .blogPage {
    .container {
      .row {
        .postCard {
          margin-bottom: 36px;
          a {
            display: flex;
            flex-flow: column;
          }
          .postTitle {
            min-height: initial;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  .blogItem {
    .container {
      margin-bottom: 0;
    }
    .blogTitle {
      font-size: 24px;
      margin-bottom: 24px;
    }
    .blogShare {
      display: none;
    }
    .blogShareMobile {
      display: block !important;
      margin: 36px 0;
    }
  }
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 90%;
        max-height: 50vh;
      }
    }
  }
  .Contact {
    margin-top: 0;

    .container {
      .row {
        .formItem {
          margin-bottom: 20px;
        }
      }
      .mfieldsContainer {
        display: none;
      }
      .mfieldsContainer-mobile {
        display: block;
        .mfields {
          margin-top: 0;
        }
      }
    }
  }
  .FaqPage {
    margin-top: 0;
  }
}

@media (pointer: coarse) {
  .heroBanner,
  .heroBannerMobile,
  .Fullwidthimage,
  .FullwidthimageMobile {
    background-attachment: scroll !important;
  }
}
